import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';

import Work from '../../containers/Work/Work';
import Skills from '../Skills/Skills'
import Education from '../Education/Education';
import Contact from '../Contact/Contact';
import Nav from '../Nav/Nav';
import './Header.css';
import { faHome, faBriefcase, faCogs, faGraduationCap, faIdCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {isBrowser} from "react-device-detect";

class Header extends Component {
  render() {
    let home = isBrowser ? 'Home' : <FontAwesomeIcon icon={faHome} />
    let work = isBrowser ? 'Work Experience' : <FontAwesomeIcon icon={faBriefcase} />
    let skills = isBrowser ? 'Technical Skills' : <FontAwesomeIcon icon={faCogs} />
    let edu = isBrowser ? 'Education' : <FontAwesomeIcon icon={faGraduationCap} />
    let contact = isBrowser ? 'Contact' : <FontAwesomeIcon icon={faIdCard} />
    return (
      <div>
        <div className="nav">
          <nav className="navbar">
            <NavLink className="navLink" smooth to='/' exact activeClassName="selected" strict> {home} </NavLink>
            <NavLink className="navLink" smooth to='/#work' exact activeClassName="selected" strict> {work} </NavLink>
            <NavLink className="navLink" smooth to='/#skills' exact activeClassName="selected" strict> {skills} </NavLink>
            <NavLink className="navLink" smooth to='/#education' exact activeClassName="selected" strict> {edu} </NavLink>
            <NavLink className="navLink" smooth to='/#contact' exact activeClassName="selected" strict> {contact} </NavLink>
          </nav>
        </div>


        <Switch>
          <Route path="/#work" exact component={Work} />
          <Route path="/#skills" exact component={Skills} />
          <Route path="/#education" exact component={Education} />
          <Route path="/#contact" exact component={Contact} />
          <Route path="/" exact component={Nav} />
          <Redirect to="/" />
        </Switch>
      </div>
    );
  }

}

export default Header;
