import React from 'react';

import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import './Skills.css';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const skills = (props) => {
  return (
    <div id="skills">
      <h1>TECHNICAL SKILLS</h1>
      <div id="tables">
        <div className="skillWrapper">
           {/* <h3>Technical Skills</h3> */}
          <table className="skillsTable">
            <tbody>
              <tr>
                <td className="skillsContent">HTML5</td>
                <td className="skillsContent">CSS3</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={80}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={70}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">JavaScript</td>
                <td className="skillsContent">React.js</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={75}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={80}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">Bootstrap</td>
                <td className="skillsContent">Material UI</td>
              </tr>
              <tr>
              <td className="progressBar"><Progress status="active" percent={55}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={75}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">Node.js</td>
                <td className="skillsContent">Express.js</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={70}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={55}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">MongoDB</td>
                <td className="skillsContent">PostgreSQL</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={75}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={40}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">Git</td>
                <td className="skillsContent">Bitbucket</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={80}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={65}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              {/* <tr>
                <td className="skillsContent">Bitbucket</td>
                <td className="skillsContent">Data Structures</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={65}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={55}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>

        {/* <div className="skillWrapper2">
          <h3>Other Skills</h3>
          <table className="skillsTable">
            <tbody>
              <tr>
                <td className="skillsContent">MATLAB</td>
                <td className="skillsContent">Simulink</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={65}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={70}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">C programming</td>
                <td className="skillsContent">PLC programming</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={85}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={90}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">LabView</td>
                <td className="skillsContent">VBA</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={55}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={65}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">GE’s MarkVIe Controller</td>
                <td className="skillsContent">Microcontrollers – 8051, ARM</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={65}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={75}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent">Flex5</td>
                <td className="skillsContent">SCADA, HMI</td>
              </tr>
              <tr>
                <td className="progressBar"><Progress status="active" percent={60}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
                <td className="progressBar"><Progress status="active" percent={35}
                  theme={{
                    active: {
                      symbol: <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'rgb(65, 151, 221)' }} />,
                      color: 'rgb(65, 151, 221)'
                    }
                  }} />
                </td>
              </tr>
              <tr>
                <td className="skillsContent"></td>
                <td className="skillsContent"></td>
              </tr>
              <tr>
                <td className="progressBar"></td>
                <td className="progressBar"></td>
              </tr>
              <tr>
                <td className="skillsContent"></td>
                <td className="skillsContent"></td>
              </tr>
            </tbody>
          </table>
        </div> */}
      </div>
    </div>
  )
}

export default skills;