import React from 'react';

import { faPhoneAlt, faEnvelope, faGlobe, faMapMarkedAlt, faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Contact.css';
import { isBrowser } from "react-device-detect";

const contact = (props) => {
    let size = isBrowser ? '2x' : null;
    return (
        <div id="contact" align="center">
            <h1>CONTACT DETAILS</h1>
            <div className="contactClass">
                <table className="table">
                    <tbody>
                        <tr>
                            <td className="icon"><FontAwesomeIcon icon={faPhoneAlt} size={size} /></td>
                            <td className="content">(669)278-9442</td>
                        </tr>
                        <tr>
                            <td className="icon"><FontAwesomeIcon icon={faEnvelope} size={size} /></td>
                            <td className="content">shah.swati.91@gmail.com</td>
                        </tr>
                        <tr>
                            <td className="icon"><FontAwesomeIcon icon={faGlobe} size={size} /></td>
                            <td className="content">www.swati-shah.com</td>
                        </tr>
                        <tr>
                            <td className="icon"><FontAwesomeIcon icon={faMapMarkedAlt} size={size} /></td>
                            <td className="content">San Jose, California</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <a href="/"><FontAwesomeIcon className="topArrow" icon={faArrowAltCircleUp} /></a>
        </div>

    )
}

export default contact;