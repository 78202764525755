import React from 'react';
import ReactTooltip from 'react-tooltip';
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from '../../assets/images/profile.JPG';
import './Home.css';
import Button from '../Button/Button';


const home = (props) => {
    return (
        <div>
            <span className="title">
                <h1>Swati Shah</h1>
                <h5>Frontend Web Developer | Full Stack Developer</h5>
            </span>
            <div className="profileDiv" align="center" >
                <img id="profile" src={Image} alt='profile' />
            </div>
            <div className="home">
                <div id="introDiv">
                    <ReactTooltip id='linkedin' type='info' place='bottom' clickable={true} effect='float'>
                        https://www.linkedin.com/in/swatisha
                    </ReactTooltip>
                    <ReactTooltip id='github' type='info' place='bottom' clickable={true} effect='float'>
                        Private Repositories at https://github.com/ShahSwati/
                    </ReactTooltip>
                    <ReactTooltip id='email' type='info' place='bottom' clickable={true} effect='float' >
                        shah.swati.91@gmail.com
                    </ReactTooltip>
                    <ReactTooltip id='phone' type='info' place='bottom' clickable={true} effect='float'>
                        (669)278-9442
                    </ReactTooltip>
                    <div className="contentWrapper">
                        <p>
                            My career began in Bangalore, India as Controls Engineer writing code for wind turbines and I have arrived as Full Stack Developer in California, USA. As I am starting out fresh in web development, I am very excited about learning new techologies and programming languages. I did a few internships in frontend web development. While doing those internships I got some exposure to backend development which piqued my interest and I learnt backend programming along with algorithms and data structures, making me a backend engineer.
                        </p>
                        <p>
                            When I am not coding, you will find me painting, crafting or traveling.
                        </p>
                        <a href="https://www.linkedin.com/in/swatisha/" data-tip data-for='linkedin'><FontAwesomeIcon className="homeIcons" icon={faLinkedin} /></a>
                        <a href="https://github.com/ShahSwati/" data-tip data-for='github'><FontAwesomeIcon className="homeIcons" icon={faGithub} /></a>
                        <a data-tip data-for='email' href="mailto:shah.swati.91@gmail.com"><FontAwesomeIcon className="homeIcons" icon={faEnvelope} /></a>
                        <FontAwesomeIcon className="homeIcons" icon={faPhone} data-tip data-for='phone' />
                        <br />
                        <a href='https://drive.google.com/file/d/1LDqE1DtdZ1mSPTGj6N0UJQ1vUj-h_Ihw/view?usp=sharing' download="Swati Shah's Resume.pdf" target="_blank" rel="noopener noreferrer">
                            <Button className="download">Download Resume</Button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default home;