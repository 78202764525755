import React, { useState } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Button from '../../components/Button/Button';
import './Work.css';
import { faWrench, faBriefcase, faStickyNote, faAward } from "@fortawesome/free-solid-svg-icons";
import { faReact, faJs } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Work() {
  const [open_React, setOpen_React] = useState(false);
  const [open_PIOC, setOpen_PIOC] = useState(false);
  const [open_Cert, setOpen_Cert] = useState(false);
  const [open_Chainyard, setOpen_Chainyard] = useState(false);
  const [open_ControlsEngg, setOpen_ControlsEngg] = useState(false);
  const [open_Intern, setOpen_Intern] = useState(false);
  const [open_Paper, setOpen_Paper] = useState(false);
  const [open_KYT, setOpen_KYT] = useState(false);
  const [open_PMR, setOpen_PMR] = useState(false); //PMR - Precise Mind Report

  return (
    <div id="work">
      <h1>WORK EXPERIENCE & ACHIEVEMENTS</h1>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faBriefcase} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Frontend Web Developer</h3>
          <h4 className="vertical-timeline-element-subtitle">Project</h4>
          <h4>July, 2021 - Current</h4>
          <p>
            Developed a web application to collect patient responses for mental health diagnostic screening. Based on patient responses, the application produces a summarized report for the doctor describing the likelihood of different types of mental health disorders. 
            The questionnaire and likelihood are built using doctor’s expertise in this field for 10+ years. The application saves time for the doctor and his/her team in initial screening, thereby improving his productivity.
          </p>
          <p>
            The application can easily be adapted to other use cases like car servicing cost estimator, customers’ risk tolerance for investing etc.
          </p>
          <br />
          <Button
            clicked={() => setOpen_PMR(!open_PMR) }
          >
            {open_PMR ? "Read Less" : "Read More"}
          </Button>
          {open_PMR ? (
            <div className='blockContent'>
              <h4>Technologies used</h4>
              <ul>
                <li>React.js</li>
                <li>Redux</li>
              </ul>
            </div>
          ) : null }
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faWrench} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Full Stack Web Developer</h3>
          <h4 className="vertical-timeline-element-subtitle">Personal Project</h4>
          <h4>May, 2020 - Current</h4>
          <p>
            Developed a website where a single user can link their multiple eBay accounts (if they hold more than one) and get information at one place. 
            The application fetches various information and gives the user following details:
          </p>

          <ul div className='blockContent'>
            <li>A 30 days sales summary (can show summary for custom dates as well)</li>
            <li>State-wise distribution of sales for given dates (default 30 days prior to today)</li>
            <li>Sales information</li>
            <li>Shipping information</li>
            <li>eBay and other fees information</li>
            <li>Any returns/refunds issued and their status</li>
          </ul>
          <br />
          <Button
            clicked={() => setOpen_KYT(!open_KYT) }
          >
            {open_KYT ? "Read Less" : "Read More"}
          </Button>
          {open_KYT ? (
            <div className='blockContent'>
              <h4>Technologies used</h4>
              <ul>
                <li>React.js</li>
                <li>Node.js</li>
                <li>Express.js</li>
                <li>Postgresql</li>
                <li>Implemented login form with user authentication using JSON Wen Tokens (JWT)</li>
              </ul>
            </div>
          ) : null }
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faBriefcase} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Frontend Web Developer (Intern)</h3>
          <h4 className="vertical-timeline-element-subtitle">Miles</h4>
          <h4>January, 2020 - April, 2020</h4>
          <p>
            Develop a React.js based website for internal use. Make changes to existing Angular.js based website as per requirement. Add new routes and modify the backend code as required.
          </p>
          <p>
            Added multiple functionalities to help customer service representative and marketing team for seamlessly managing users, their subscriptions and rewards, saving them a lot of time by tasks manually also bypassing the need for the backend team to process requests.
          </p>
          <br />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faBriefcase} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Frontend Web Developer (Intern)</h3>
          <h4 className="vertical-timeline-element-subtitle">Chainyard</h4>
          <h4>October 2019 - January, 2020</h4>
          <p>
            Played role in developing frontend of 'Trust Your Supplier' project using React.js. Added new features, identified issues in existing UI/UX and fixed them.
            Gave multiple suggestions for improving the platform which were happily accepted by my mentor and taken forward.
          </p>
          <br />
          <Button
            clicked={() => setOpen_Chainyard(!open_Chainyard) }
          >
            {open_Chainyard ? "Read Less" : "Read More"}
          </Button>
          {open_Chainyard ?
            <div className='blockContent'>
              <h4>Technologies used</h4>
              <ul>
                <li>React.js</li>
                <li>Context APIs</li>
                <li>React Router</li>
                <li>Hooks</li>
              </ul>
            </div>
          : null}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faReact} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Completed Certified Course on React.js</h3>
          <h4 className="vertical-timeline-element-subtitle"><a href="https://www.udemy.com/certificate/UC-H8KZMYZ8/">Udemy</a></h4>
          <h4>September, 2019 - November, 2019</h4>
          {/* <p>
            <a href="https://www.udemy.com/certificate/UC-H8KZMYZ8/">Certificate</a>
          </p> */}
          <br />
          <Button
            clicked={() => setOpen_React(!open_React) }
          >
            {open_React ? "Read Less" : "Read More"}
          </Button>
          {open_React ?
            <div className='blockContent'>
              <h4>Learnt</h4>
              <ul>
                <li>React Basics</li>
                <li>CSS Modules</li>
                <li>React Router</li>
                <li>Forms and form validation</li>
                <li>Context API</li>
                <li>Hooks</li>
                <li>Redux</li>
              </ul>
            </div>
          : null}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faWrench} size='7x' style={{ marginLeft: '-55px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Full Stack Web Developer</h3>
          <h4 className="vertical-timeline-element-subtitle">Personal Project</h4>
          <h4>July, 2019</h4>
          <p>
            Developed a personal website named <span style={{fontStyle: "italic"}}>Put It On Canvas</span> to showcase my creativity and love for art. It depicts a few art pieces that I have created using oil on canvas.
          </p>
          <br />
          <Button
            clicked={() => { setOpen_PIOC(!open_PIOC); }}
          >
            {open_PIOC ? "Read Less" : "Read More"}
          </Button>
          {open_PIOC ?
            <div className='blockContent'>
              <ul>
                <li>Developed an end-to-end web application using React.js for frontend and Node.js and Express.js for backend.</li>
                <li>Implemented login form with user authentication using JSON Wen Tokens (JWT)</li>
                <li>Implemented 'Drop a message' form which uses third party API for sender's E-mail ID verification.</li>
                <li>Deployed the backend of the application on Heroku and frontend on AWS S3.</li>
                <li>Used AWS 53 for routing.</li>
              </ul>
              <p className='website'><a href="http://www.put-it-on-canvas.com">Go to website</a></p>
            </div>
            : null}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faJs} size='7x' style={{ marginLeft: '-50px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Completed Certified Course on Vanilla JavaScript and Backend</h3>
          <h4 className="vertical-timeline-element-subtitle"><a href="https://www.udemy.com/certificate/UC-T030YWN4/">Udemy</a></h4>
          <h4>May, 2019 - July, 2019</h4>
          {/* <p>
            <a href="https://www.udemy.com/certificate/UC-T030YWN4/">Certificate</a>
          </p> */}
          <br />
          <Button
            clicked={() => { setOpen_Cert(!open_Cert) }}
          >
            {open_Cert ? "Read Less" : "Read More"}
          </Button>
          {open_Cert ?
            <div className='blockContent'>
              <h4>Learnt</h4>
              <ul>
                <li>HTML5</li>
                <li>CSS3</li>
                <li>JavaScript</li>
                <li>Bootstrap 4</li>
                <li>DOM Manipulation</li>
                <li>jQuery</li>
                <li>Node.js</li>
                <li>Express.js</li>
                <li>REST API</li>
                <li>MongoDB</li>
                <li>Passport.js</li>
                <li>App deployment</li>
              </ul>
            </div>
            : null}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faBriefcase} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Operability & Controls Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle">Renewable Engineering, General Electric - Bangalore, India</h4>
          <h4>June, 2015 - January, 2018</h4>
          <h6>(Left to accompany my husband in another country)</h6>
          <p>
            Developed and programmed new controls logic/algorithm in wind turbine controller to increase efficiency of wind turbine. Identified operational problems with installed turbines and resolved them.
          </p>
          <br />
          <Button
            clicked={() => { setOpen_ControlsEngg(!open_ControlsEngg); }}
          >
            {open_ControlsEngg ? "Read Less" : "Read More"}
          </Button>
          {open_ControlsEngg ?
            <div className='blockContent'>
              <h4>Key Responsibilities</h4>
              <ul>
                <li>Build and maintain tools to manage field parameters in an efficient and intuitive manner.</li>
                <li>Update field code based on simulation results performed in Software In Loop (SIL).</li>
                <li>Diagnose and resolve system problems and communicate to field engineers or customers to reduce turbine downtime.</li>
                <li>Simulate turbine performance to catch and resolve operability issues prior to commissioning on the field.</li>
                <li>Investigate turbine failure and perform root cause analysis.</li>
                <li>Perform Failure Mode & Effect Analysis(FMEA) for newly developed control strategies before implementing them on new turbine.</li>
              </ul>
              <h4>Key achievements</h4>
              <ul>
                <li>Developed a tool using VBA for handing-off field parameters and trained colleagues – locally and remotely – to use this tool. This helped in reducing parameter conversion time by 75%.</li>
                <li>Modified the wind turbine controls design making it possible to produce power at wind speeds greater than cut out.</li>
              </ul>
            </div>
            : null}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faAward} size='7x' style={{ marginLeft: '-43px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Professional Award</h3>
          <h4 className="vertical-timeline-element-subtitle">Project of the Month Award</h4>
          <h4>January, 2017</h4>
          <p>
            Awarded for developing VBA based easy-to-use and automated 'Parameter Conversion' tool that makes field parameters hand-off faster with less efforts.
          </p>
          <br />

        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faBriefcase} size='7x' style={{ marginLeft: '-56px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Intern</h3>
          <h4 className="vertical-timeline-element-subtitle">Global Research, General Electric - Bangalore, India</h4>
          <h4>May, 2014 - April, 2015</h4>
          <p>
            Performed a detailed study in the area of NOx reduction through aftertreatment in combined cycle power plants. Also, designed & evaluated advanced control strategies for emissions reduction for other power generation systems.
          </p>
          <br />
          <Button
            clicked={() => { setOpen_Intern(!open_Intern); }}
          >
            {open_Intern ? "Read Less" : "Read More"}
          </Button>
          {open_Intern ?
            <div className='blockContent'>
              <h4>Key Responsibilities</h4>
              <ul>
                <li>Evaluate and implement different control strategies to combined cycle power plant model in simulation environment.</li>
                <li>Perform simulations using Model Predictive Control to inject ammonia in selective catalyst reduction (SCR) system to meet the allowable emission limits of NOx and CO2 gases.</li>
                <li>Perform state-of-the-art study and evaluate different control strategies to control carbon emissions in diesel engines.</li>
              </ul>
              <h4>Key achievements</h4>
              <ul>
                <li>Controlled ammonia injection required to maintain combined cycle power plant emissions under allowable limit.</li>
                <li>Published a paper within GE that recommends new control strategies to meet the latest regulations on diesel engine emissions.</li>
              </ul>
            </div>
            : null}
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
          contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
          iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
          animate={true}
          icon={<FontAwesomeIcon icon={faStickyNote} size='7x' style={{ marginLeft: '-49px' }} />}
        >
          <h3 className="vertical-timeline-element-title">Paper publication</h3>
          <h4 className="vertical-timeline-element-subtitle">International Federation of Automatic Control (IFAC)</h4>
          <h4>December, 2014</h4>
          <p>
            <a href="https://www.sciencedirect.com/science/article/pii/S2405896315030505">Optimal Ammonia Injection for Emissions Control in Power Plants</a>
          </p>
          <br />
          <Button
            clicked={() => { setOpen_Paper(!open_Paper) }}
          >
            {open_Paper ? "Read Less" :  "Read More" }
          </Button>
          {open_Paper ?
            <div className='blockContent'>
              <h4>Abstract</h4>
              <p>One of the effective devices to reduce NOx in power plants is the ammonia based selective catalyst reduction (SCR) system. In this paper, kinetic SCR model simulator is tuned and validated with representative site data from an actual combined-cycle power plant. This simulator is then used for the development and implementation of model predictive control (MPC) for SCR systems to control the plant startup NOx emissions along with maintaining ammonia slip within the acceptable limit. Also, the results of linear and nonlinear MPC are compared. The startup NOx reduction obtained in both cases is seen to be close to ~85%, but the computational effort required for both these approaches differ significantly.</p>
            </div>
            : null}
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div >
  );
}

export default Work;