import React, { Component } from 'react';
// import Scroll from 'react-scroll';

import Home from '../Home/Home';
import Work from '../../containers/Work/Work';
import Skills from '../Skills/Skills';
import Education from '../Education/Education';
import Contact from '../Contact/Contact';


class Navbar extends Component {

  render() {
    return (
      <div>
        <Home/>
        <Work/>
        <Skills/>
        <Education/>
        <Contact/>
      </div>
    )
  }
}

export default Navbar;