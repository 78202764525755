import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import './Education.css';
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Education() {
    return (
        <div id="education">
            <h1>EDUCATION</h1>
            <br/>
            <br/>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
                    contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
                    iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
                    animate={true}
                    icon={<FontAwesomeIcon icon={faGraduationCap} size='7x' style={{ marginLeft: '-69px' }} />}
                >
                    <h3 className="vertical-timeline-element-title">Masters of Technology</h3>
                    <h4 className="vertical-timeline-element-subtitle">Control & Automation</h4>
                    <h4>2013 - 2015</h4>
                    <h4 className="vertical-timeline-element-subtitle">Institute of Technology, Nirma University, Gujarat, India</h4>
                    <br />
                    <h4 className="vertical-timeline-element-subtitle">Grade: 8.91/10</h4>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: 'whitesmoke', color: 'black', boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.15), 0 3px 10px 0 rgba(0, 0, 0, 0.15)" }}
                    contentArrowStyle={{ borderRight: '7px solid  whitesmoke' }}
                    iconStyle={{ background: 'rgb(50, 39, 65)', color: '#fff', blockSize: '10px 10px' }}
                    animate={true}
                    icon={<FontAwesomeIcon icon={faGraduationCap} size='7x' style={{ marginLeft: '-69px' }} />}
                >
                    <h3 className="vertical-timeline-element-title">Bachelors of Engineering</h3>
                    <h4 className="vertical-timeline-element-subtitle">Instrumentation & Control</h4>
                    <h4>2009 - 2013</h4>
                    <h4 className="vertical-timeline-element-subtitle">Gujarat Technological University, Gujarat, India</h4>
                    <br />
                    <h4 className="vertical-timeline-element-subtitle">Grade: 8.93/10</h4>
                </VerticalTimelineElement>
            </VerticalTimeline>
        </div>
    )
}

export default Education;